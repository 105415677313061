//rfc is for react function based component 
import React from 'react'
//impt import prop types
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom';

export default function Navbar(props) {
  
        
  return (
     <nav className={`navbar navbar-expand-lg navbar-${props.mode.theme} bg-${props.mode.theme}`}>
    <div className="container-fluid">
    <Link className="navbar-brand" to="/">{props.title}</Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/about">{props.aboutText}</Link>
        </li>
      </ul>
      <div class={`form-check form-switch text-${props.mode.text}`}>
        <input className="form-check-input" type="checkbox" role="switch" id="redMode" onClick={props.toggleModeRed} />
        <label className="form-check-label mx-2" htmlFor="redMode" id="switchLabel">Red Mode</label>
      </div>
      <div class={`form-check form-switch text-${props.mode.text}`}>
        <input className="form-check-input" type="checkbox" role="switch" id="greenMode" onClick={props.toggleModeGreen} />
        <label className="form-check-label mx-2" htmlFor="greenMode" id="switchLabel">Green Mode</label>
      </div>
      <div class={`form-check form-switch text-${props.mode.text}`}>
        <input className="form-check-input" type="checkbox" role="switch" id="yellowMode" onClick={props.toggleModeYellow} />
        <label className="form-check-label mx-2" htmlFor="yellowMode" id="switchLabel">Yellow Mode</label>
      </div>
      <div class={`form-check form-switch text-${props.mode.text}`}>
        <input className="form-check-input" type="checkbox" role="switch" id="darkMode" onClick={props.toggleMode} />
        <label className="form-check-label mx-2" htmlFor="darkMode" id="switchLabel">Dark Mode</label>
      </div>
      <form className="d-flex" role="search">
      
        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button className="btn btn-outline-success" type="submit">Search</button>
      </form>
    </div>
  </div>
</nav>
  )
}

Navbar.propTypes = {title: PropTypes.string.isRequired,
    aboutText: PropTypes.string
}
Navbar.defaultProps = {
    title: 'Set Title Here',
    aboutText: 'About',

}