import React, { useState } from 'react'

export default function About(props) {
    
    // const [myStyle, setmyStyle]= useState({
    //     color: 'black',
    //     backgroundColor: 'white',
    // })
    // const [state, setState] = useState('Dark')

    let myStyle={
        color: props.mode.theme==='dark'?'white':'black',
        backgroundColor: props.mode.theme==='dark'?'rgb(4 55 109)':'white',
        border: 'solid 1px',
        borderColor: props.mode.theme==='dark'?'white':'black',
    }
    let myStyle2={
        
        color: props.mode.theme==='dark'?'white':'black',
       
    }




    // const toggleStyle=()=>{
    //     if(myStyle.color==='black')
    //     {setmyStyle({
    //         color: 'white',
    //         backgroundColor: 'black',  
    //     })
    //     setState('Light');
    // }
        
    //     else
    //     {
    //         setmyStyle({
    //             color:'black',
    //             backgroundColor: 'white',
    //         })
    //         setState('Dark');
    //     }
    // }
    
    return (
        
        <div className='container' style={myStyle2}>
           
            <h1 style={{marginTop: '2px'}} >About Us</h1>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item" style={myStyle} >
                    <h2 className="accordion-header">
                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={myStyle}>
                            <strong> Analyze Your Text </strong>
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample" >
                        <div className="accordion-body" style={myStyle}>
                            TextUtils gives you the tools to manipulate your text in the way you want.    
                        </div>
                    </div>
                </div>
                <div className="accordion-item" style={myStyle} >
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={myStyle} >
                            <strong>Free to Use</strong>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample" >
                        <div className="accordion-body" style={myStyle}>
                            TextUtils is completely free to use. You only need a browser and our website to manipulate your text in the way you want.    
                        </div>
                    </div>
                </div>
                <div className="accordion-item" style={myStyle}>
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" style={myStyle}>
                            <strong>Browser Compatible</strong>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div className="accordion-body" style={myStyle}>
                            TextUtil works on all the modern Browsers like Chrome, Safari, Firefox, Opera, Edge.    
                        </div>
                    </div>
                </div>
            </div>
            {/* <button className="btn btn-primary my-3" onClick={toggleStyle}>Enable {state} Mode</button> */}
        </div>
      
    )
}


