import React from 'react'

export default function Footer(props) {
  return (
    <div>
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3" style={{color: props.mode.theme==='light'?'black':'white'}}>
          <li className="nav-item"><a href="/" className={`nav-link px-2 text-${props.mode.theme==='light'?'muted':'white'}`}>Home</a></li>
          <li className="nav-item"><a href="/" className={`nav-link px-2 text-${props.mode.theme==='light'?'muted':'white'}`}>Features</a></li>
          <li className="nav-item"><a href="/" className={`nav-link px-2 text-${props.mode.theme==='light'?'muted':'white'}`}>Pricing</a></li>
          <li className="nav-item"><a href="/" className={`nav-link px-2 text-${props.mode.theme==='light'?'muted':'white'}`}>FAQs</a></li>
          <li className="nav-item"><a href="/" className={`nav-link px-2 text-${props.mode.theme==='light'?'muted':'white'}`}>About</a></li>
        </ul>
        <p className={`text-center text-${props.mode.theme==='light'?'muted':'white'}`}>© Made By Rehnoor Aulakh</p>
      </footer>
    </div>
  )
}
