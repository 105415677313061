import React, { useState } from 'react'
import './App.css';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import Alert from './components/Alert';
import Footer from './components/Footer';
import About from './components/About';

 import {
BrowserRouter as Router,
Route,
Routes,
Link,
} from "react-router-dom";


function App() {
  const [mode, setMode] = useState({
    text: 'dark',
    theme: 'light',
  });  //Whether Dark Mode is enabled or not
  const [alert, setAlert] = useState(null);
  const showAlert=(message, type)=>{
    setAlert({
      msg: message,
      type: type,
    })
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }
  const toggleMode=()=>
    {
        if(mode.theme==='light')
          {
            setMode({
              theme:'dark',
              text: 'light',
            });
            document.body.style.backgroundColor='#042743';
            showAlert('Dark Mode has been enabled','success');
            document.title='TextUtils -Dark Mode';
          }
          else{
            setMode({
              theme:'light',
              text: 'dark',
            });
            document.body.style.backgroundColor='white';
            showAlert('Light Mode has been enabled','success');
            document.title='TextUtils -Home';
          }
          setInterval(() => {
            document.title='TextUtils is Amazing';
          }, 2000);
          setInterval(() => {
            document.title='Install TextUtils Now';
          }, 2500);
    }
    const toggleModeGreen=()=>
      {
        if(mode.theme==='light')
          {
            setMode({
              theme:'dark',
              text: 'light',
            });
            document.body.style.backgroundColor='#1b6b1b';
            showAlert('Green Mode has been enabled','success');
            document.title='TextUtils -Green Mode';
          }
          else{
            setMode({
              theme:'light',
              text: 'dark',
            });
            document.body.style.backgroundColor='white';
            showAlert('Light Mode has been enabled','success');
            document.title='TextUtils -Home';
          }
      }
      const toggleModeRed=()=>
        {
          if(mode.theme==='light')
            {
              setMode({
                theme:'dark',
                text: 'light',
              });
              document.body.style.backgroundColor='#aa0000';
              showAlert('Red Mode has been enabled','success');
              document.title='TextUtils -Red Mode';
            }
            else{
              setMode({
                theme:'light',
                text: 'dark',
              });
              document.body.style.backgroundColor='white';
              showAlert('Light Mode has been enabled','success');
              document.title='TextUtils -Home';
            }
        }
        const toggleModeYellow=()=>
          {
            if(mode.theme==='light')
              {
                setMode({
                  theme:'dark',
                  text: 'light',
                });
                document.body.style.backgroundColor='#9c9c04';
                showAlert('Yellow Mode has been enabled','success');
                document.title='TextUtils -Yellow Mode';
              }
              else{
                setMode({
                  theme:'light',
                  text: 'dark',
                });
                document.body.style.backgroundColor='white';
                showAlert('Light Mode has been enabled','success');
                document.title='TextUtils -Home';
              }
          }
  return (
    
    <Router>
      <Navbar title="TextUtils" aboutText="About Us" mode={mode} toggleMode={toggleMode} toggleModeRed={toggleModeRed} toggleModeGreen={toggleModeGreen} toggleModeYellow={toggleModeYellow}/>
      <Alert alert={alert} />
      
      <div className="container my-3">
      {/* <TextForm heading="Enter text to analyze"  mode={mode} showAlert={showAlert} /> */}
      {/*
      /users --> Component 1
      /users/home --> Component 2
    
  */}
      <Routes>
            <Route exact path="/about" element={<About mode={mode} />}>
            </Route>
            <Route exact path="/" element={<TextForm heading="Enter text to analyze"  mode={mode} showAlert={showAlert} />}>
            </Route>
          </Routes>
    
        <Footer mode={mode}> </Footer>
       
      </div>

    </Router>
  );
}

export default App;
