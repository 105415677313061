import React,{useState} from 'react'

export default function TextForm(props) {
    const [text,setText]= useState('');

    const handleUpClick=()=>
    {
            //console.log("UpperCase was clicked"+text);
            let newText=text.toUpperCase();
            setText(newText);
            props.showAlert('UpperCase has been applied','success');
    }
    const handleLwClick=()=>
        {
                //console.log("LowerCase was clicked"+text);
                let newText=text.toLowerCase();
                setText(newText);
                props.showAlert('LowerCase has been applied','success');
        }
    const handleSnClick=()=>
        {
                var newText=text.split('.');
                var final='';
                var first='';
                if(newText.length===0)
                {
                final=text.charAt(0).toUpperCase();
                final=final+text.slice(1,text.length);
                }
                else
                {
                for(var i=0;i<newText.length;i++)
                    {
                        first=newText[i].charAt(0).toUpperCase();
                        
                        final=final+first+newText[i].slice(1);
                        final=final+'.';
                    }
                    final=final.slice(0,final.length-1);
                }
                setText(final);
                props.showAlert('SentenceCase has been applied','success');
        }
    const handleClearClick=()=>
        {
            setText('');
            props.showAlert('Text has been cleared','success');
        }
    const handleCopy=()=>
        {
            var text=document.getElementById("myBox");
            text.select();
            navigator.clipboard.writeText(text.value);
            document.getSelection().removeAllRanges();
            props.showAlert('Copied to Clipboard','success');
        }
    const handleRemove=()=>
        {
            let newText= text.split(/[ ]+/);
            setText(newText.join(" "));
            props.showAlert('Extra Spaces Removed','success');
        }
    const handleOnChange=(event)=>
        {
            //console.log("OnChange was CLicked");
            setText(event.target.value);
        }
    const handleStatus=()=>{
        if(text==='')
            {
                return 0;
            }
            else if(text.charAt(text.length-1)===' '){
                return text.split(/\s+/).length-1;

            }
            else
            {
                return text.split(/\s+/).length;
            }
    }
  
  
  return (
    <>
    <div className='container' style={{color: props.mode.theme==='light'?'black':'white'}} >
        <h1 >{props.heading}</h1>
      <form>
        <div className="mb-3 my-3">
        <textarea className='form-control' id="myBox" rows="8" value={text} placeholder='Enter Text Here' onChange={handleOnChange} style={{backgroundColor: props.mode.theme==='dark'?'rgb(116 183 255)':'white', color: props.mode.theme==='light'?'black':'white'}}></textarea>
        </div>
        </form>
        <button disabled={text.length===0} className="btn btn-primary my-1 mx-1" onClick={handleUpClick} >Convert to UpperCase</button> &nbsp;
        <button disabled={text.length===0} className="btn btn-primary my-1 mx-1" onClick={handleLwClick} >Convert to LowerCase</button> &nbsp;
        <button disabled={text.length===0} className="btn btn-primary my-1 mx-1" onClick={handleClearClick} >Clear Text</button> &nbsp;
        <button disabled={text.length===0} className="btn btn-primary my-1 mx-1" onClick={handleSnClick} >Convert to SentenceCase</button> &nbsp;
        <button disabled={text.length===0} className="btn btn-primary my-1 mx-1" onClick={handleCopy} >Copy Text</button> &nbsp;
        <button disabled={text.length===0} className="btn btn-primary my-1 mx-1" onClick={handleRemove} >Remove Extra Spaces</button> 
        
        <hr></hr>
        <h2 className="my-2">Your Text Summary</h2>
        
        <p>{handleStatus()} Words, {text.length} Characters</p>
        <p>{handleStatus()*0.08} Minutes Read</p>
        <h3>Preview</h3>
        {text.length>0?text:'Nothing to Preview'}
    </div>
    </>
  )
}
